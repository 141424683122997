/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable brace-style */
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { FC } from 'react'
import { ServerPageProps } from '../core/interfaces'

export const NFA_DEFAULT_KEY = 'nfa'

export type ServerSideProcessor<T = ServerPageProps> = (
  context: GetServerSidePropsContext,
  pageProps: T
) => Promise<GetServerSidePropsResult<T>>

export type CustomPageRender<T = ServerPageProps> = {
  Render: FC<T>
  getServerSideProps?: ServerSideProcessor<T>
}

export type ParsePageDefinition = (source: string) => {
  render: string
  key: string
}

export const parsePageDefinition: ParsePageDefinition = (source) => {
  const parts = source.split(':')
  if (parts.length === 1) {
    return {
      render: NFA_DEFAULT_KEY, // this is the default connector
      key: parts[0].trim(),
    }
  }

  return {
    render: parts[0].toLowerCase().trim(),
    key: parts.slice(1).join(':').trim(),
  }
}
