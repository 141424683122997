import { CustomPageRender, NFA_DEFAULT_KEY } from '../render-connector'
import react from './PureReact'
import cdn from './Cdn'
import web from './Web'
import nfaTemplate from './NfaTemplate'

export interface Renders {
  [key: string]: CustomPageRender
}

const renders: Renders = {
  [NFA_DEFAULT_KEY]: nfaTemplate,
  react,
  cdn,
  web,
  experimentalssr: nfaTemplate, // TODO: Deprecated
}

export default renders
