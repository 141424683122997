/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useSelector, useStore } from 'react-redux'
import { dependencies } from '../../config'
import { CustomPageRender } from '../../core/render-connector'

const PureReactRender: CustomPageRender = {
  Render: (props) => {
    const RootComponent = dependencies[props.namespace].components[props.name]
    const state = useSelector((state) => state[props.namespace])
    const store = useStore()
    const attributes = { ...props, state, key: null, store }
    return RootComponent
      ? React.createElement(RootComponent as any, attributes, null)
      : null
  },
}

export default PureReactRender
