/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react'
import Iframe from 'react-iframe'
import { CustomPageRender } from '../../core/render-connector'

const WebRender: CustomPageRender = {
  Render: ({ name }) => {
    return (
      <Iframe
        url={name}
        className=""
        display="block"
        position="relative"
        styles={{
          height: '100vh' /* Viewport-relative units */,
          width: '100vw',
        }}
      />
    )
  },
}

export default WebRender
