/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react'
import { CustomPageRender } from '../render-connector'

const CdnRender: CustomPageRender = {
  Render: ({ name }) => <div>{name}</div>,
}

export default CdnRender
